import { defaults } from '../../index.js';
import { gsapConfig } from '../../index.js';
import { gsap } from "gsap/dist/gsap";

window.addEventListener("DOMContentLoaded", () => {
  gsapConfig();
  toggleMobile();
  toggleHeight();
  scrollTo();
});


function toggleMobile() {
  const nav = document.getElementById("navMobile");
  const navLinks = document.querySelectorAll(".nav-link-mobile");
  const bars = document.querySelectorAll(".bar");
  addClickEvents();
  return;
  function addClickEvents() {
    const triggers = document.querySelectorAll(".nav-trigger");
    let navClosed = true;
    triggers.forEach(trigger => {
      trigger.addEventListener('click', event => {
        event.preventDefault();
        if (navClosed) {
          openNav();
          navClosed = false;
        }
        else {
          closeNav();
          navClosed = true;
        }
      });
    });
    return;
  }
  function openNav() {
    gsap.fromTo(nav, { y: "-100%" }, { y: 0});
    gsap.fromTo(bars[0], { y: -8, rotate: 0 }, { y: 0, rotate: 225 });
    gsap.fromTo(bars[1], { opacity: 1 }, { opacity: 0, duration: defaults.duration * 0.3 });
    gsap.fromTo(bars[2], { y: 8, rotate: 0 }, { y: 0, rotate: -225 });
    gsap.fromTo(navLinks, { opacity: 0, y: 25 }, { opacity: 1, y: 0, duration: defaults.duration * 1, delay: defaults.duration, stagger: 0.05});
    return;
  }
  function closeNav() {
    gsap.fromTo(nav, { y: 0 }, { y: "-100%"});
    gsap.fromTo(bars[0], { y: 0, rotate: 225 }, { y: -8, rotate: 0 });
    gsap.fromTo(bars[1], { opacity: 0 }, { opacity: 1, duration: defaults.duration * 0.6 });
    gsap.fromTo(bars[2], { y: 0, rotate: -225 }, { y: 8, rotate: 0 });
    gsap.fromTo(navLinks, { y: 0 }, { opacity: 0, y: 20, duration: 0 });
    return;
  }
}

function toggleHeight() {
  const navDesktop = document.getElementById("navDesktop");
  window.addEventListener("scroll", () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      navDesktop.classList.add("nav-small");
    } else {
      navDesktop.classList.remove("nav-small");
    }
  });
  return;
}

function scrollTo() {
  const navLinks = document.querySelectorAll(".nav-link");
  const offsetY = 50;
  navLinks.forEach(link => {
    link.addEventListener('click', event => {
      event.preventDefault();
      const tl = gsap.timeline();
      if (link.classList.contains("nav-logo")) {
        tl.to(window, { scrollTo: { y: "header", offsetY: 80 } });
      }
      if (link.classList.contains("nav-about")) {
        tl.to(window, { scrollTo: { y: ".about", offsetY: offsetY } });
      }
      if (link.classList.contains("nav-services")) {
        tl.to(window, { scrollTo: { y: ".services", offsetY: offsetY } });
      }
      if (link.classList.contains("nav-pricing")) {
        tl.to(window, { scrollTo: { y: ".pricing", offsetY: offsetY } });
      }
      if (link.classList.contains("nav-contact")) {
        tl.to(window, { scrollTo: { y: ".contact", offsetY: offsetY } });
      }
    });
  });
  return;
}



