import { gsap } from "gsap/dist/gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export const defaults = {
 duration: 0.8,
 ease: "Power1.easeInOut",
};

export const gsapConfig = function () {
 gsap.defaults({ ease: defaults.ease, duration: defaults.duration });
 gsap.registerPlugin(ScrollToPlugin);
 return;
}
